<template>
  <div class="elevation-1 white rounded p-3">
    <custom-snackbar ref="snackbar" />

    <!-- Modal exclusão -->
    <v-dialog v-model="deleteDialog" max-width="500px">
      <v-card class="bg-danger text-white">
        <v-card-title class="headline word-breaker">
          <p>
            {{deleteDialogText}}
          </p>
        </v-card-title>

        <v-card-actions>
          <v-spacer />
          <v-btn color="white" text outlined large @click="toggleDeleteDialog">Não</v-btn>
          <v-btn color="white" text outlined large @click="deleteDialogHandleConfirm">Sim</v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Adiciona Perfil -->
    <v-dialog
      v-model="addProfileDialog"
      transition="dialog-bottom-transition"
      max-width="650"
    >
      <v-card>
        <v-toolbar color="info" dark>
          <span class="headline">Adicionar perfil</span>
        </v-toolbar>
        <v-card-text v-if="addProfileDialogLoading">
          <div class="text-center p-5">
            <h4 class="mb-4">
              Aguarde...
            </h4>
            <v-progress-circular
              :size="100"
              :width="2"
              color="primary"
              indeterminate
            />
          </div>
        </v-card-text>
        <v-form
          ref="form"
          @submit.prevent="handleAddProfile"
          v-else
        >
          <v-card-text>
            <v-container>
              <v-row>
                <v-col sm="12">
                  <v-text-field
                    dense
                    outlined
                    label="Nome do perfil"
                    v-model="addProfileDialogName"
                    :rules="profileNameRule"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="blue darken-1"
              text
              @click="toggleAddProfileDialog(false)"
            >
              Fechar
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="handleAddProfile"
            >
              Salvar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <!-- Adiciona Permissão para Perfil -->
    <v-dialog
      v-model="managePermissionsDialog"
      transition="dialog-bottom-transition"
      max-width="1000"
    >
      <v-card>
        <v-toolbar
          color="info"
          dark
        >
          <span class="headline">Adicionar permissões ao perfil</span>
        </v-toolbar>
        <v-form
          ref="form"
          v-if="showManagePermissionsForm"
        >
          <v-card-text>
            <v-container>
              <v-row>
                <v-col md="5">
                  <v-data-table
                    dense
                    class="elevation-1"
                    show-select
                    disable-pagination
                    hide-default-footer
                    group-by="grupo"
                    ref="refAvailablePermissionsTable"
                    v-model="selectedToAddProfilePermissions"
                    :items-per-page="$dataTableConfig.getItemsPerPage()"
                    :footer-props="$dataTableConfig.getFooterProps()"
                    :loading-text="$dataTableConfig.getLoadText()"
                    :headers="availablePermissionsTableHeader"
                    :items="availablePermissionsData"
                    :loading="availablePermissionsLoading"
                  >
                    <template v-slot:top>
                      <div class="text-center">
                        <h4 class="p-4 mb-3">Permissões Disponíveis</h4>
                      </div>
                    </template>

                    <template v-slot:[`group.header`]="{ group, headers, isOpen, toggle }">
                      <td :colspan="headers.length" @click="toggle" class="cursor-pointer">
                        <v-btn text icon small>
                          <v-icon>{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}</v-icon>
                        </v-btn>
                        <span style="font-weight:bold;">{{ group.toUpperCase() }}</span></td>
                    </template>

                    <template v-slot:[`item.ativo`]="{ item }">
                      <v-chip
                        class="ma-1"
                        color="green"
                        text-color="white"
                        small
                        v-if="item.ativo"
                      >
                        Ativo
                      </v-chip>
                      <v-chip
                        class="ma-1"
                        color="red"
                        text-color="white"
                        small
                        v-else
                      >
                        Inativo
                      </v-chip>
                    </template>
                    <template v-slot:no-data>
                      Nenhuma permissão encontrada
                    </template>
                  </v-data-table>
                </v-col>
                <v-col md="2">
                  <div class="d-flex flex-column align-center">
                    <v-btn
                      class="mx-2"
                      fab
                      dark
                      x-small
                      color="primary"
                      @click="handleAddPermissionsToProfile"
                    >
                      <v-icon dark>
                        mdi-chevron-right
                      </v-icon>
                    </v-btn>
                    <v-btn
                      class="mx-2 mt-4"
                      fab
                      dark
                      x-small
                      color="primary"
                      @click="handleRemoveProfilePermission"
                    >
                      <v-icon dark>
                        mdi-chevron-left
                      </v-icon>
                    </v-btn>
                  </div>
                </v-col>
                <v-col md="5">
                  <v-data-table
                    dense
                    class="elevation-1"
                    show-select
                    disable-pagination
                    hide-default-footer
                    group-by="grupo"
                    ref="refProfilePermissionsTable"
                    v-model="selectedToRemoveProfilePermissions"
                    :items-per-page="$dataTableConfig.getItemsPerPage()"
                    :footer-props="$dataTableConfig.getFooterProps()"
                    :loading-text="$dataTableConfig.getLoadText()"
                    :headers="profilePermissionsTableHeader"
                    :items="profilePermissionsData"
                    item-key="permissao_id"
                    :loading="profilePermissionsDataLoading"
                  >
                    <template v-slot:top>
                      <div class="text-center">
                        <h4 class="p-4 mb-3">Permissões Aplicadas</h4>
                      </div>
                    </template>

                    <template v-slot:[`group.header`]="{ group, headers, isOpen, toggle }">
                      <td :colspan="headers.length" @click="toggle" class="cursor-pointer">
                        <v-btn text icon small>
                          <v-icon>{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}</v-icon>
                        </v-btn>
                        <span style="font-weight:bold;">{{ group.toUpperCase() }}</span></td>
                    </template>

                    <template v-slot:[`item.ativo`]="{ item }">
                      <v-chip
                        class="ma-1"
                        color="green"
                        text-color="white"
                        small
                        v-if="item.ativo"
                      >
                        Ativo
                      </v-chip>
                      <v-chip
                        class="ma-1"
                        color="red"
                        text-color="white"
                        small
                        v-else
                      >
                        Inativo
                      </v-chip>
                    </template>
                    <template v-slot:no-data>
                      Nenhuma permissão encontrada
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="blue darken-1"
              text
              @click="toggleManagePermissionsDialog(false)"
            >
              Fechar
            </v-btn>
          </v-card-actions>
        </v-form>
        <v-card-text v-else>
          <div class="text-center p-5">
            <h4 class="mb-4">
              Aguarde...
            </h4>
            <v-progress-circular
              :size="100"
              :width="2"
              color="primary"
              indeterminate
            />
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-container class="p-0">
      <v-row>
        <v-col class="md12 text-right">
          <v-btn
            color="info"
            dark
            outlined
            class="mb-2 mt-5 la-pull-right"
            @click="toggleAddProfileDialog"
          >
            Adicionar perfil
            <v-icon dark>
              mdi-plus
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <!-- Filtros -->
      <v-row class="mt-5">
        <v-col>
          <v-text-field
            dense
            outlined
            v-model="search"
            label="Pesquisar"
            append-icon="mdi-magnify"
          />
        </v-col>
      </v-row>

      <!-- Tabela de Dados -->
      <v-row>
        <v-col>
          <v-data-table
            dense
            class="elevation-1"
            sort-by="id"
            :headers="headers"
            :items="profilesData"
            :search="search"
            :items-per-page="$dataTableConfig.getItemsPerPage()"
            :footer-props="$dataTableConfig.getFooterProps()"
            :loading-text="$dataTableConfig.getLoadText()"
            :loading="loading"
          >
            <template v-slot:[`item.ativo`]="{ item }">
              <v-chip
                class="ma-1"
                color="green"
                text-color="white"
                small
                v-if="item.ativo"
              >
                Ativo
              </v-chip>
              <v-chip
                class="ma-1"
                color="red"
                text-color="white"
                small
                v-else
              >
                Inativo
              </v-chip>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small @click="managePermissionsDialogAction(item)" title="Gerenciar permissões">
                mdi-cog
              </v-icon>
              <v-icon small @click="removeProfileAction(item)" title="Apagar perfil">
                mdi-delete
              </v-icon>
            </template>
            <template v-slot:no-data>
              Nenhum perfil encontrada
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'

export default {
  data: () => ({
    search: '',
    loading: false,
    deleteDialogText: '',
    deleteDialogRemoveId: null,
    deleteDialog: false,
    deleteDialogHandleConfirm: () => {},
    addProfileDialog: false,
    addProfileDialogName: '',
    addProfileDialogLoading: false,
    managePermissionsDialog: false,
    showManagePermissionsForm: false,
    profileId: null,
    profilesData: [],
    permissionsData: [],
    availablePermissionsLoading: false,
    profilePermissionsDataLoading: false,
    profilePermissionsData: [],
    allPermissionsData: [],
    selectedToAddProfilePermissions: [],
    selectedToRemoveProfilePermissions: [],
    profileNameRule: [(v) => !!v || 'Necessário informar o nome do perfil'],
  }),
  computed: {
    headers () {
      return [
        { text: '#', value: 'id' },
        { text: 'Perfil', value: 'nome' },
        { text: 'Ações', value: 'actions', sortable: false },
      ]
    },
    availablePermissionsTableHeader () {
      return [
        { text: 'Selecionar Todos', value: 'permissao_nome_formatado' },
      ]
    },
    profilePermissionsTableHeader () {
      return [
        { text: 'Selecionar Todos', value: 'permissao_nome_formatado' },
      ]
    },
    availablePermissionsData () {
      const profilePermissions = this.profilePermissionsData.map((profilePermission) => {
        return profilePermission.permissao_id
      })

      const teste = this.allPermissionsData.filter((permission) => {
        return !profilePermissions.includes(permission.id)
      })

      return teste
    },
  },
  watch: {
    dialogPermissionsOpen (val) {
      val || this.closePermissionsDialog()
    },
  },
  mounted () {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Configurações' },
      { title: 'Sistema' },
      { title: 'Perfis Acesso', route: 'configuracoes/sistema/perfis-acesso' },
    ])

    this.initialize()
  },
  methods: {
    initialize () {
      this.fetchProfiles()
    },

    fetchProfiles () {
      this.loading = true

      ApiService.get('/perfil').then((res) => {
        this.profilesData = res.data.data
        this.fetchAllPermissions()
      }).catch((error) => {
        console.error('Erro: ', error)
      }).finally(() => {
        this.loading = false
      })
    },

    fetchAllPermissions () {
      this.loading = true

      ApiService.get('/permissao').then((res) => {
        this.permissionsData = this.allPermissionsData = res.data.data
      }).catch((error) => {
        console.error('Erro: ', error)
      }).finally(() => {
        this.loading = false
      })
    },

    fetchProfilePermissions (perfilId) {
      this.profilePermissionsDataLoading = true

      ApiService.get(`/permissao-perfil/${perfilId}`).then((res) => {
        this.profilePermissionsData = res.data.data
        this.showManagePermissionsForm = true
        this.profilePermissionsDataLoading = false

        this.$nextTick(() => {
          this.collapseAllGroups(['refProfilePermissionsTable', 'refAvailablePermissionsTable'])
        })
      }).catch((error) => {
        console.error('Erro: ', error)
        this.profilePermissionsDataLoading = false
      })
    },

    managePermissionsDialogAction (item) {
      this.profileId = item.id
      this.toggleManagePermissionsDialog()
      this.fetchProfilePermissions(item.id)
    },

    selectAllPermissionsAction () {
      this.selectedToAddProfilePermissions = [...this.allPermissionsData]
    },

    removeProfilePermissionAction (item) {
      this.toggleDeleteDialog(`Tem certeza que deseja remover a permissão "${item.permissao_nome}"?`)
      this.deleteDialogHandleConfirm = this.handleRemoveProfilePermission
      this.deleteDialogRemoveId = item.id
    },

    removeProfileAction (item) {
      this.toggleDeleteDialog(`Tem certeza que deseja remover o perfil "${item.nome}"?`)
      this.deleteDialogHandleConfirm = this.handleRemoveProfile
      this.deleteDialogRemoveId = item.id
    },

    toggleAddProfileDialog (show = true) {
      this.addProfileDialog = show
      this.addProfileDialogLoading = false
    },

    toggleDeleteProfileDialog (show = true) {
      this.deleteProfileDialog = show
    },

    toggleManagePermissionsDialog (show = true) {
      this.selectedToAddProfilePermissions = []
      this.managePermissionsDialog = show
    },
    toggleDeleteDialog (title = '') {
      this.deleteDialogText = title
      this.deleteDialog = !this.deleteDialog
    },
    handleAddProfile () {
      if (this.$refs.form.validate()) {
        this.addProfileDialogLoading = true

        ApiService.post('/perfil', {
          nome: this.addProfileDialogName,
        }).then(() => {
          this.$refs.snackbar.show('Sucesso', 'Perfil cadastrado com sucesso')
          this.toggleAddProfileDialog(false)
          this.addProfileDialogName = ''
        }).catch((error) => {
          this.$refs.snackbar.show('Erro', `Não foi possível cadastrar as permissões no perfil. Mensagem: ${JSON.stringify(error.message)}`, 'danger')
        }).finally(() => {
          this.addProfileDialogLoading = false
          this.fetchProfiles()
        })
      }
    },

    handleRemoveProfile () {
      this.addProfileDialogLoading = true

      ApiService.delete(`/perfil/${this.deleteDialogRemoveId}`, {
        nome: this.addProfileDialogName,
      }).then(() => {
        this.$refs.snackbar.show('Sucesso', 'Perfil removido')
      }).catch((error) => {
        this.$refs.snackbar.show('Erro', `Não foi possível remover o perfil. Mensagem: ${JSON.stringify(error.message)}`, 'danger')
      }).finally(() => {
        this.toggleDeleteDialog()
        this.fetchProfiles()
      })
    },

    handleAddPermissionsToProfile () {
      if (this.selectedToAddProfilePermissions.length === 0) {
        this.$refs.snackbar.show('Alerta', 'Nenhuma permissão selecionada', 'alert')
        return false
      }

      ApiService.post('/permissao-perfil', {
        perfil_id: this.profileId,
        permissoes: this.selectedToAddProfilePermissions.map((permission) => {
          return permission.id
        }),
      }).then(() => {
        this.$refs.snackbar.show('Sucesso', 'Permissões cadastradas com sucesso')
        this.selectedToAddProfilePermissions = []
        this.fetchProfilePermissions(this.profileId)
      }).catch((error) => {
        this.$refs.snackbar.show('Erro', `Não foi possível cadastrar as permissões no perfil. Mensagem: ${JSON.stringify(error.message)}`, 'danger')
      })
    },

    handleRemoveProfilePermission () {
      ApiService.delete('/permissao-perfil', {
        data: {
          perfil_id: this.profileId,
          permissoes: this.selectedToRemoveProfilePermissions.map((permission) => {
            return permission.permissao_id
          }),
        },
      }).then((res) => {
        this.$refs.snackbar.show('Sucesso', 'Permissões removidas do perfil')
        this.fetchProfilePermissions(this.profileId)
      }).catch((error) => {
        this.$refs.snackbar.show('Erro', `Não foi possível remover as permissões do perfil. Mensagem: ${JSON.stringify(error.message)}`, 'danger')
      }).finally(() => {
        this.selectedToRemoveProfilePermissions = []
      })
    },

    collapseAllGroups (tables) {
      // Data table groups are closed/collapsed by default
      // shame on Vuetify for not having this feature

      tables.forEach((table) => {
        const refTable = this.$refs[table]
        const keys = Object.keys(refTable.$vnode.componentInstance.openCache)

        keys.forEach((x) => {
          refTable.$vnode.componentInstance.openCache[x] = false
        })
      })
    },
  },
}
</script>
